import React from 'react';
import PropTypes from 'prop-types';
import generalFailedPC from '../fetch/generalFailedPC';
import Tooltip from '../general/Tooltip';
import FormModal from '../fetch/FormModal';
import generalNoParameterFormPC from '../fetch/generalNoParameterFormPC';
import {Task} from '../../constants/propTypesDefinitions';
import {createFetchTaskRemove} from '../../backend/apiCalls';
import {Button} from 'antd';
import {Trans, t} from '@lingui/macro';

/**
 * @dusan
 */

class TaskRemove extends React.PureComponent {
    static propTypes = {
        onFinish: PropTypes.func.isRequired,
        size: PropTypes.string,
        task: Task.isRequired,
    };

    render() {
        const {task = {}, size, onFinish} = this.props;
        return <FormModal
            openNode={
                <Tooltip title={<Trans>Odstránenie typu úlohy</Trans>}>
                    <Button type="danger" icon="delete" size={size}/>
                </Tooltip>
            }
            values={{}}
            onFinishSuccessful={onFinish}
            title={
                <span>
                    <Trans>Odstránenie typu úlohy</Trans>
                    {' '}
                    {'"' + task.name + '"'}
                </span>
            }
            Form={generalNoParameterFormPC(
                <Trans>Chcete odstrániť typ úlohy?</Trans>,
                createFetchTaskRemove(task.id),
            )}
            Response={null}
            Failed={generalFailedPC(t`Nepodarilo sa odstrániť typ úlohy.`)}
        />;
    }

}

export default TaskRemove;